import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'
import decode from 'jwt-decode'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    usuario: null,

    listaUsuariosStore: [],
    usuarioStore: {},
    listaTrabajadoresStore: [],
    trabajadorStore: null,
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setUsuario(state, usuario) {
      state.usuario = usuario
    },
  },
  actions: {
    guardarToken({ commit }, token) {
      commit("setToken", token)
      commit("setUsuario", decode(token))
      localStorage.setItem("token-trabajadores", token)
      router.push({ name: 'Home' })

    },
    autoLogin({ commit }) {
      let token = localStorage.getItem("token-trabajadores")
      if (token) {
        commit("setToken", token)
        commit("setUsuario", decode(token))
        router.push({ name: 'Home' })
      }
    },
    salir({ commit }) {
      commit("setToken", null)
      commit("setUsuario", null)
      localStorage.removeItem("token-trabajadores")
      router.push({ name: 'Login' })
    },
  },
  modules: {
  }
})
