<template>
  <q-page class=" q-pa-lg ">

    <!-- DIALOGO DE CONFIRMACION -->
    <q-dialog v-model="dialogConfirm" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="mdi-delete-alert" color="primary" text-color="white" />
          <span class="text-h5 q-ml-lg">Estás seguro de eliminar el trabajador {{ this.trabajador }}.?</span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Aceptar" color="primary" @click="PutEstadoTrabajador()" />
          <q-btn flat label="Cerrar" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- DIALOGO DE CONFIRMACION -->
    <q-dialog v-model="dialogConfirmIA" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="mdi-delete-alert" color="primary" text-color="white" />
          <span class="text-h5 q-ml-lg">Estás seguro cambiar el estatus del trabajador {{ this.trabajador }}.?</span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Aceptar" color="primary" @click="PutEstadoTrabajadorAI()" />
          <q-btn flat label="Cerrar" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- DIALOGO PARA CREAR UN NUEVO -->
    <q-dialog v-model="dialogNuevo" persistent transition-show="scale" transition-hide="scale">
      <Trabajador @cierraVentana="dialogNuevo = false"></Trabajador>
    </q-dialog>

    <q-dialog full-width v-model="dialogFunciones" persistent transition-show="scale" transition-hide="scale">
      <q-card>
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Funciones</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>
        <q-card-section>
          <div class="row q-col-gutter-sm">
            <div class="col-6">
              <div class="row q-col-gutter-sm">

                <div class="col-12">
                  <q-bar dense class="bg-primary text-white">
                    <q-space />
                    <div>FUNCIÓN PRINCIPAL</div>
                    <q-space />
                  </q-bar>
                </div>

                <div class="col-12 col-md-8">
                  <q-input v-model="funcionLista.actividad" autogrow dense filled label="Función" />
                </div>

                <div class="col-12 col-md-4">
                  <!-- <q-input v-model="funciones.porcentaje" dense filled label="Porcentaje" /> -->
                  <q-field dense filled label="Porcentaje" v-model="funcionLista.porcentaje" @blur="validatePrice">
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                      <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                        v-bind="moneyFormat" v-show="floatingLabel" />
                    </template>
                  </q-field>
                </div>

                <div class="col-12">
                  <q-bar dense class="bg-primary text-white">
                    <q-space />
                    LISTA DE ACTIVIDADES
                    <q-space />
                  </q-bar>
                </div>

                <div class="col-12 col-md-8">
                  <q-input v-model="actividades.actividad" dense filled autogrow label="Actividad" />
                </div>

                <div class="col-12 col-md-4">
                  <q-field dense filled label="Porcentaje" v-model="actividades.porcentaje" @blur="validatePorcentaje">

                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                      <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                        v-bind="moneyFormat" v-show="floatingLabel" />
                    </template>

                    <template v-slot:after>
                      <q-btn round color="primary" icon="mdi-plus" @click="agregarActividad()">
                        <q-tooltip>
                          Agregar
                        </q-tooltip>
                      </q-btn>
                    </template>
                  </q-field>
                </div>

                <div class="col-12">
                  <q-bar dense class="bg-primary text-white">
                    {{ this.funcionLista.actividad }}
                    <q-space />
                    {{ this.funcionLista.porcentaje + ' %' }}
                  </q-bar>
                </div>
                <div class="col-12">
                  <q-table title="" class="shadow-0" :data="funcionLista.funciones" :columns="columnsFunciones"
                    row-key="actividad" hide-header hide-bottom>

                    <template v-slot:body="props">
                      <q-tr :props="props">
                        <q-td auto-width>

                          <q-btn size="md" color="red" rounded flat dense @click="eliminarPrimeraLista(props.row)"
                            icon="mdi-delete">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                              content-style="font-size: 14px">Eliminar</q-tooltip>
                          </q-btn>
                        </q-td>
                        <q-td key="actividad" :props="props">{{ props.row.actividad }}</q-td>
                        <q-td key="porcentaje" :props="props">{{ props.row.porcentaje + ' %' }}</q-td>
                      </q-tr>
                    </template>
                  </q-table>
                </div>
                <div class="col-12 text-right">
                  <q-btn color="primary" outline icon="mdi-plus" label="Agregar" @click="agregar()" />
                </div>
              </div>

            </div>

            <div class="col-6">
              <q-table hide-bottom title="Funciones a Desarrollar" class="shadow-0" :data="data"
                :columns="columnsFunciones" row-key="actividad">

                <template v-slot:header="props">
                  <q-tr :props="props">
                    <q-th auto-width />
                    <q-th v-for="col in props.cols" :key="col.name" :props="props">
                      {{ col.label }}
                    </q-th>
                  </q-tr>
                </template>

                <template v-slot:body="props">
                  <q-tr :props="props">
                    <q-td auto-width>
                      <q-btn size="sm" color="accent" round dense @click="props.expand = !props.expand"
                        :icon="props.expand ? 'remove' : 'add'" />
                      <q-btn size="md" color="red" rounded flat dense @click="eliminarListaChida(props.row)"
                        icon="mdi-delete">
                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                          content-style="font-size: 14px">Eliminar</q-tooltip>
                      </q-btn>
                    </q-td>
                    <q-td key="actividad" :props="props">{{ props.row.actividad }}</q-td>
                    <q-td key="porcentaje" :props="props">{{ props.row.porcentaje + ' %' }}</q-td>
                  </q-tr>
                  <q-tr v-show="props.expand" :props="props">
                    <q-td colspan="100%">
                      <q-table title="" class="shadow-0" :data="props.row.funciones" :columns="columnsFunciones"
                        row-key="actividad" hide-header hide-bottom>
                        <template v-slot:body="props">
                          <q-tr :props="props">
                            <q-td key="actividad" :props="props">{{ props.row.actividad }}</q-td>
                            <q-td key="porcentaje" :props="props">{{ props.row.porcentaje + ' %' }}</q-td>
                          </q-tr>
                        </template>
                      </q-table>
                      <!-- <div class="text-left">This is expand slot for row above: {{ props.row.name }}.</div> -->
                    </q-td>
                  </q-tr>
                </template>
              </q-table>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <div class="row	flex-left ">
      <div class="col-12 col-md-12 text-right ">
        <q-btn class="q-mr-sm" round color="primary" icon="mdi-update" @click="consultar()">
          <q-tooltip>
            Consultar
          </q-tooltip>
        </q-btn>
        <q-btn v-if="token.group == 'admin'" color="primary" icon="mdi-plus" label="Agregar nuevo trabajador"
          @click="nuevoRegistro" />
      </div>
    </div>
    <div class="q-mt-md">
      <q-table class="shadow-0" title="Trabajadores" :data="items" :columns="columns" row-key="_id" :filter="filter"
        :loading="loading" :pagination="pagination">

        <template v-slot:loading>
          <q-inner-loading showing color="primary" />
        </template>

        <template v-slot:top-right>
          <q-input style="width: 500px;" filled borderless dense debounce="300" v-model="filter" placeholder="Buscar">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>

        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td auto-width key="acciones">
              <q-btn v-if="token.group == 'admin'" size="md" color="red" rounded flat dense @click="confirm(props.row)"
                icon="mdi-delete">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                  content-style="font-size: 14px">Eliminar</q-tooltip>
              </q-btn>
              <q-btn v-if="token.group == 'admin'" size="md" color="primary" rounded flat dense
                @click="editar(props.row)" icon="mdi-pencil">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                  content-style="font-size: 14px">Editar</q-tooltip>
              </q-btn>
              <q-btn size="md" color="purple" rounded flat dense @click="verTarjeta(props.row)"
                icon="mdi-badge-account">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                  content-style="font-size: 14px">Tarjeta</q-tooltip>
              </q-btn>
              <q-btn v-if="props.row.estatus == 'Activo' && token.group == 'admin'" size="md" color="red" rounded flat
                dense @click="cambiarEstado(props.row)" icon="mdi-account-cancel">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                  content-style="font-size: 14px">Cambiar a Inactivo</q-tooltip>
              </q-btn>
              <q-btn v-if="token.group == 'admin' && props.row.estatus == 'Inactivo'" size="md" color="green" rounded
                flat dense @click="cambiarEstado(props.row)" icon="mdi-account-check">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                  content-style="font-size: 14px">Cambiar a Activo</q-tooltip>
              </q-btn>
              <q-btn size="md" color="blue" rounded flat dense @click="verFunciones(props.row)"
                icon="mdi-format-list-bulleted-square">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                  content-style="font-size: 14px">Funciones</q-tooltip>
              </q-btn>
            </q-td>
            <q-td key="nombre" :props="props">{{ props.row.nombre + ' ' + props.row.apellidos }}</q-td>
            <!-- <q-td key="apellidos" :props="props">{{ props.row.apellidos }}</q-td> -->
            <q-td key="fechaNacimiento" :props="props">{{ FormatDate(props.row.fechaNacimiento) }}</q-td>
            <q-td key="genero" :props="props">{{ props.row.genero }}</q-td>
            <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>

            <q-td key="codigoPostal" :props="props">{{ props.row.codigoPostal }}</q-td>
            <q-td key="telefono" :props="props">{{ props.row.telefono }}</q-td>
            <q-td key="correo" :props="props">{{ props.row.correo }}</q-td>
            <q-td key="puesto" :props="props">{{ props.row.puesto }}</q-td>
            <q-td key="estatus" :props="props">
              <q-badge v-if="props.row.estatus == 'Activo'" color="green">
                {{ props.row.estatus }}
              </q-badge>
              <q-badge v-else color="red">
                {{ props.row.estatus }}
              </q-badge>
            </q-td>
            <q-td auto-width key="archivos" :props="props">
              <q-btn v-if="props.row.cv.base64 != ''" size="md" color="red" rounded flat dense
                @click="verArchivoCV(props.row)" icon="mdi-pdf-box">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px">Ver
                  CV
                </q-tooltip>
              </q-btn>
              <q-btn v-if="props.row.contrato.base64 != ''" size="md" color="red" rounded flat dense
                @click="verArchivoContrato(props.row)" icon="mdi-pdf-box">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px">Ver
                  Contrato</q-tooltip>
              </q-btn>
              <q-btn v-if="props.row.identificacion.base64 != ''" size="md" color="red" rounded flat dense
                @click="verArchivoIdentificacion(props.row)" icon="mdi-pdf-box">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px">Ver
                  Identificación</q-tooltip>
              </q-btn>
            </q-td>

          </q-tr>
        </template>
      </q-table>
    </div>
  </q-page>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import axios from "axios";
import moment from 'moment';
import Trabajador from '../components/Trabajadores/Trabajador.vue'
import print from 'print-js'
import { QSpinnerCube } from 'quasar'
import { Tarjeta } from '../components/PDF/Tarjeta.js'
import { Money } from 'v-money'

export default {
  name: 'Home',
  components: {
    Money,
    Trabajador
  },
  data() {
    return {
      dialogFunciones: false,
      //funciones: [],
      columnsFunciones: [
        { name: 'actividad', align: 'center', label: 'Actividad', field: 'actividad', },
        { name: 'porcentaje', align: 'center', label: 'Porcentaje', field: 'porcentaje' },
      ],

      filter: '',
      loading: false,
      columns: [
        { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', },
        { name: 'nombre', align: 'center', label: 'Nombre', field: 'nombre', },
        { name: 'fechaNacimiento', align: 'center', label: 'Fecha de Nacimiento', field: 'fechaNacimiento' },
        // { name: 'apellidos', align: 'center', label: 'Apellidos', field: 'apellidos', sortable: true },
        { name: 'genero', align: 'center', label: 'Genero', field: 'genero' },
        { name: 'rfc', align: 'center', label: 'RFC', field: 'rfc', classes: 'text-center  text-dark  bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white', },
        { name: 'codigoPostal', align: 'center', label: 'Código Postal', field: 'codigoPostal', sortable: true, },
        { name: 'telefono', align: 'center', label: 'Teléfono', field: 'telefono', },
        { name: 'correo', align: 'center', label: 'Correo', field: 'correo', },
        { name: 'puesto', align: 'center', label: 'Puesto', field: 'puesto' },
        { name: 'estatus', align: 'center', label: 'Estatus', field: 'estatus', sortable: true },
        { name: 'archivos', align: 'center', label: 'Archivos', field: 'archivos', },
        // { name: 'contrato', align: 'center', label: 'Contrato', field: 'contrato', },
      ],
      data: [],
      dialogNuevo: false,
      pagination: {
        sortBy: 'nombre',
        descending: true,
        page: 1,
        rowsPerPage: 10
        // rowsNumber: xx if getting data from a server
      },
      dialogConfirmIA: false,
      dialogConfirm: false,
      trabajador: '',

      funcionLista: {
        actividad: '',
        porcentaje: 0,
        funciones: []
      },

      data: [],

      actividades: {
        actividad: '',
        porcentaje: 0,
      },

      moneyFormat: {
        decimal: '.',
        thousands: ',',
        precision: 2,
        masked: false,
        suffix: ' %',
      },
    }
  },

  watch: {
    isDarkMode(val) {
      this.$q.dark.set(val);
    }
  },
  computed: {
    token() {
      return this.$store.state.usuario;
    },
    fehaIMasked() {
      moment.locale('es-mx');
      return this.fechaI ? moment.utc(this.fechaI).format('DD/MMMM/yyyy') : ''
    },
    fehaFMasked() {
      moment.locale('es-mx');
      return this.fechaF ? moment.utc(this.fechaF).format('DD/MMMM/yyyy') : ''
    },
    items() {
      return this.$store.state.listaTrabajadoresStore
    },
    item() {
      return this.$store.state.trabajadorStore
    }

  },
  created() {
    this.consultar();
  },
  methods: {
    async actualizar() {
      this.$store.state.trabajadorStore.actividades = this.data;
      console.log(this.item)

      this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Actualizando trabajador. Espere...', messageColor: 'white' })

      try {
        let response = await axios.put('Trabajadores/PutTrabajador/', this.item)
        console.log(response)

        let indice = this.$store.state.listaTrabajadoresStore.findIndex(x => x._id === this.item._id);
        Object.assign(this.$store.state.listaTrabajadoresStore[indice], this.item)

        this.$q.loading.hide()
        this.$q.notify({ type: 'positive', position: 'top-right', message: `El trabajador ha sido actualizado exitosamente.` })
      } catch (error) {
        console.log(error)
        this.$q.loading.hide()
        this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al actualizar, verifique su información e intentelo de nuevo.', color: 'red' })
      }
    },

    async eliminarListaChida(data) {
      let editedIndex = this.data.indexOf(data)
      this.data.splice(editedIndex, 1)

      await this.actualizar()

    },
    async eliminarPrimeraLista(data) {
      let editedIndex = this.funcionLista.funciones.indexOf(data)
      this.funcionLista.funciones.splice(editedIndex, 1)
    },

    agregarActividad() {

      let totalPorcentaje = this.funcionLista.funciones.reduce((a, b) => a + b.porcentaje, 0);

      let total = totalPorcentaje + this.actividades.porcentaje

      if (total > 100) {
        this.$q.notify({
          type: 'negative', message: 'Error, el total del porcentaje de las funciones no puede ser mayor a 100%.',
          color: 'red', position: 'top-right'
        })
        this.actividades.porcentaje = 0
        return
      }

      const minValue = 0; // valor mínimo
      const maxValue = 100; // valor máximo


      if (this.actividades.porcentaje <= minValue || this.actividades.porcentaje > maxValue) {
        this.$q.notify({
          type: 'negative', message: 'Error, el valor del porcentaje de la actividad debe ser menor a 100% y mayor 0%.',
          color: 'red', position: 'top-right'
        })
        this.actividades.porcentaje = 0
        return
      }
      console.log(this.actividades);
      const obje = this.actividades
      this.funcionLista.funciones.push(obje);

      this.actividades = {
        actividad: '',
        porcentaje: 0
      }
    },

    async agregar() {

      let totalPorcentaje = this.$store.state.trabajadorStore.actividades.reduce((a, b) => a + b.porcentaje, 0);

      let total = totalPorcentaje + this.funcionLista.porcentaje
      if (total > 100) {
        this.$q.notify({
          type: 'negative', message: 'Error, el total del porcentaje de las funciones no puede ser mayor a 100%.',
          color: 'red', position: 'top-right'
        })
        this.funcionLista.porcentaje = 0
        return
      }

      const minValue = 0; // valor mínimo
      const maxValue = 100; // valor máximo

      if (this.funcionLista.porcentaje <= minValue || this.funcionLista.porcentaje > maxValue) {
        this.$q.notify({
          type: 'negative', message: 'Error, el valor del porcentaje de la actividad debe ser menor a 100% y mayor 0%.',
          color: 'red', position: 'top-right'
        })
        this.funcionLista.porcentaje = 0
        return
      }

      let objeto = {
        actividad: this.funcionLista.actividad,
        porcentaje: this.funcionLista.porcentaje,
        funciones: this.funcionLista.funciones
      }

      this.data.push(objeto)

      this.funcionLista = {
        actividad: '',
        porcentaje: 0,
        funciones: []
      }
      await this.actualizar()
    },

    validatePrice() {
      const minValue = 0; // valor mínimo
      const maxValue = 100; // valor máximo

      if (this.funcionLista.porcentaje <= minValue || this.funcionLista.porcentaje > maxValue) {
        this.$q.notify({
          type: 'negative', message: 'Error, el valor del porcentaje de la actividad debe ser menor a 100% y mayor 0%.',
          color: 'red', position: 'top-right'
        })
        this.funcionLista.porcentaje = 0
        return
      }
    },

    validatePorcentaje() {
      const minValue = 0; // valor mínimo
      const maxValue = 100; // valor máximo

      if (this.actividades.porcentaje <= minValue || this.actividades.porcentaje > maxValue) {
        this.$q.notify({
          type: 'negative', message: 'Error, el valor del porcentaje de la actividad debe ser menor a 100% y mayor 0%.',
          color: 'red', position: 'top-right'
        })
        this.actividades.porcentaje = 0
        return
      }
    },
    async consultar() {
      this.loading = true

      try {
        let response = await axios.get("Trabajadores/GetTrabajadores/");
        console.log(response)
        this.$store.state.listaTrabajadoresStore = response.data;
        this.loading = false
      } catch (error) {
        console.log(error);
        this.loading = false
      }
    },
    editar(data) {
      this.$store.state.trabajadorStore = { ...data }
      this.dialogNuevo = true
    },
    formatCurrency(value) {
      return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    },

    FormatDate(value) {
      return moment(value).format('YYYY-MM-DD');
    },

    verArchivoCV(item) {
      let documento = item.cv.base64
      print({
        printable: documento.split(",")[1],
        type: 'pdf',
        base64: true,
      })

    },

    verArchivoContrato(item) {
      let documento = item.contrato.base64
      print({
        printable: documento.split(",")[1],
        type: 'pdf',
        base64: true,
      })
    },

    verArchivoIdentificacion(item) {
      let documento = item.identificacion.base64
      print({
        printable: documento.split(",")[1],
        type: 'pdf',
        base64: true,
      })
    },

    confirm(item) {
      this.trabajador = item.nombre + ' ' + item.apellidos

      this.$store.state.trabajadorStore = item
      this.dialogConfirm = true
    },
    cambiarEstado(item) {
      this.trabajador = item.nombre + ' ' + item.apellidos

      this.$store.state.trabajadorStore = item
      this.dialogConfirmIA = true
    },
    async PutEstadoTrabajador() {
      this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Eliminando trabajador. Espere...', messageColor: 'white' })
      let trabajador = this.$store.state.trabajadorStore

      try {
        let response = await axios.put('Trabajadores/PutEstadoTrabajador/' + trabajador._id)
        console.log(response)

        let indice = this.items.indexOf(trabajador)
        this.items.splice(indice, 1)

        this.dialogConfirm = false
        this.$q.loading.hide()
        this.$q.notify({ type: 'positive', message: `El trabajador se a eliminado exitosamente.` })
      } catch (error) {
        console.log(error)
        this.$q.loading.hide()
        this.$q.notify({ type: 'negative', message: 'Error, intentelo mas tarde.', color: 'red' })
      }
    },
    async PutEstadoTrabajadorAI() {
      this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Cambiando estatus del trabajador. Espere...', messageColor: 'white' })
      let trabajador = this.$store.state.trabajadorStore

      let estatus = 'Activo'
      if (trabajador.estatus == 'Activo') {
        estatus = 'Inactivo'
      }
      trabajador.estatus = estatus
      try {
        let response = await axios.put('Trabajadores/PutEstadoTrabajadorAI/' + trabajador._id + '/' + estatus)
        console.log(response)

        let indice = this.$store.state.listaTrabajadoresStore.findIndex(x => x._id === trabajador._id);
        Object.assign(this.$store.state.listaTrabajadoresStore[indice], trabajador)

        this.dialogConfirmIA = false
        this.$q.loading.hide()
        this.$q.notify({ type: 'positive', message: `El trabajador ha cambiado exitosamente.` })
      } catch (error) {
        console.log(error)
        this.$q.loading.hide()
        this.$q.notify({ type: 'negative', message: 'Error, intentelo mas tarde.', color: 'red' })
      }
    },
    nuevoRegistro() {
      let objeto = {
        _id: '',
        nombre: '',
        apellidos: '',
        codigoPostal: '',
        telefono: '',
        correo: '',
        direccion: '',
        foto: '',
        rfc: '',
        curp: '',
        puesto: '',
        fechaNacimiento: new Date(),
        cv: {
          base64: '',
          nombreArchivo: '',
          tipoArchivo: ''
        },
        contrato: {
          base64: '',
          nombreArchivo: '',
          tipoArchivo: ''
        },
        identificacion: {
          base64: '',
          nombreArchivo: '',
          tipoArchivo: ''
        },
        formacion: '',
        habilidades: '',
        objetivos: '',
        fechaCreacion: null,
        usuarioCreador: '',
        estatus: 'Activo',
        funciones: []
      }
      this.$store.state.trabajadorStore = objeto
      this.dialogNuevo = true
    },

    async verTarjeta(item) {
      // console.log(item.abono)
      // console.log(item.cargo)

      let base64 = await Tarjeta(item);
      console.log(base64)
      let documento = base64
      print({
        printable: documento.split(",")[1],
        type: 'pdf',
        base64: true,
      })

      // this.$store.state.pdfStore.nombre = item.numPoliza + ' | ' + item.descripcion
      // this.$store.state.pdfStore.base64 = base64.split(',')[1];

      // this.dialogPDF = true;
    },

    verFunciones(data) {
      this.dialogFunciones = true
      this.data = data.actividades
      this.$store.state.trabajadorStore = data

      this.funcionLista = {
        actividad: '',
        porcentaje: 0,
        funciones: []
      }

      this.actividades = {
        actividad: '',
        porcentaje: 0
      }
    }
  }
}
</script>

<style>
.Nuevo {
  background: #2196F3 !important;
  color: #fff !important;
}

.Cancelado {
  background: #C10015 !important;
  color: #fff !important;
}

.Aceptado {
  background: #4CAF50 !important;
  color: #fff !important;
}

.Pendiente {
  background: #FF9800 !important;
  color: #fff !important;
}
</style>
