<template>
    <q-card style="width: 1300px; max-width: 80vw;">

        <q-dialog v-model="dialogWebCam" persistent transition-show="scale" transition-hide="scale">
            <q-card style="width: 650px; max-width: 80vw;">
                <q-bar class="bg-primary text-white">
                    <div>Camara</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <web-cam ref="webcam" :device-id="deviceId" width="100%" @started="onStarted" @stopped="onStopped"
                        @error="onError" @cameras="onCameras" @camera-change="onCameraChange" />
                </q-card-section>
                <q-card-actions align="center">
                    <q-btn color="primary" round icon="mdi-camera" @click="onStart">
                        <q-tooltip>Encender</q-tooltip>
                    </q-btn>
                    <q-btn color="primary" round icon="mdi-camera-iris" @click="onCapture">
                        <q-tooltip>Capturar</q-tooltip>
                    </q-btn>
                    <q-btn color="primary" round icon="mdi-camera-off" @click="onStop">
                        <q-tooltip>Apagar</q-tooltip>
                    </q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <q-dialog v-model="dialogCargarImagen" persistent transition-show="scale" transition-hide="scale">
            <q-card style="width: 400px; max-width: 80vw;">
                <q-bar class="bg-primary text-white">
                    <div>Cargar imagen</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-12 text-center" v-if="item.foto == ''">
                            <q-avatar size="185px" font-size="130px" color="primary" text-color="white"
                                icon="mdi-account">
                            </q-avatar>
                        </div>
                        <div class="col-12 col-md-12 text-center" v-else>
                            <q-avatar size="185px" font-size="130px" color="primary" text-color="white">
                                <img :src="item.foto">
                            </q-avatar>
                        </div>
                        <div class="col-12">
                            <q-file label="Imagen" @input="uploadImagen" accept=".png, .jpeg, .jpg" dense filled
                                v-model="carga">
                                <template v-slot:prepend>
                                    <q-icon name="image" />
                                </template>

                                <template v-slot:after>
                                    <q-btn round color="primary" icon="mdi-delete" @click="borrarImagen()">
                                        <q-tooltip>
                                            Borrar
                                        </q-tooltip>
                                    </q-btn>
                                </template>
                            </q-file>
                        </div>
                    </div>
                </q-card-section>
            </q-card>

        </q-dialog>

        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Trabajador
                </q-toolbar-title>
                <q-btn v-if="item._id == ''" dense round color="green" icon="mdi-content-save" class="q-mr-sm"
                    @click="guardar()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>

                <q-btn v-else round color="orange" dense icon="mdi-content-save" class="q-mr-sm" @click="actualizar()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>

        <q-card-section class="scroll">
            <div class="row  q-col-gutter-sm">
                <div class="col-12 col-md-4">
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-12 text-center" v-if="item.foto == ''">
                            <q-avatar size="185px" font-size="130px" color="primary" text-color="white"
                                icon="mdi-account">
                            </q-avatar>
                        </div>
                        <div class="col-12 col-md-12 text-center" v-else>
                            <q-avatar size="185px" font-size="130px" color="primary" text-color="white">
                                <img :src="item.foto">
                            </q-avatar>
                        </div>
                        <div class="col-12 col-md-12 text-right" style="margin-top: -50px;">
                            <q-btn color="purple" round icon="mdi-camera" @click="dialogWebCam = true"></q-btn>
                            <q-btn color="green" round icon="mdi-image-plus" @click="dialogCargarImagen = true"></q-btn>
                        </div>
                        <div class="col-12 col-md-12">
                            <q-input v-model="item.nombre" dense filled label="Nombre" />
                        </div>
                        <div class="col-12 col-md-12">
                            <q-input v-model="item.apellidos" dense filled label="Apellidos" />
                        </div>

                        <div class="col-12 col-md-12">
                            <q-input v-model="fecha" filled label="Fecha" name="event" outlined dense>

                                <template v-slot:before>
                                    <q-icon name="event" color="primary" />
                                </template>
                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                    <q-date v-model="item.fechaNacimiento">
                                        <div class="row items-center justify-end">
                                            <q-btn v-close-popup label="Ok" color="primary" flat />
                                        </div>
                                    </q-date>
                                </q-popup-proxy>
                            </q-input>
                        </div>

                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-12">
                            <q-select dense class="full-width" v-model="item.genero" filled
                                :options="['Femenino', 'Masculino']" label="Genero" />
                        </div>
                        <div class="col-12 col-md-12">
                            <q-input v-model="item.rfc" dense filled label="RFC" />
                        </div>
                        <div class="col-12 col-md-12">
                            <q-input v-model="item.curp" dense filled label="CURP" />
                        </div>
                        <div class="col-12 col-md-12">
                            <q-input v-model="item.codigoPostal" dense filled label="Código Postal" />
                        </div>
                        <div class="col-12 col-md-12">
                            <q-input v-model="item.direccion" dense filled label="Dirección" />
                        </div>
                        <div class="col-12 col-md-12">
                            <q-input v-model="item.puesto" dense filled label="Puesto" />
                        </div>
                        <div class="col-12 col-md-12">
                            <q-input v-model="item.formacion" dense filled label="Formación" />
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="row q-col-gutter-sm">
                        <div class="col-12 col-md-12">
                            <q-input v-model="item.correo" dense filled label="Correo" />
                        </div>
                        <div class="col-12 col-md-12">
                            <q-input v-model="item.telefono" dense filled label="Teléfono" />
                        </div>
                        <div class="col-12">
                            <q-file label="CV" @input="upload2" accept=".pdf, .png, .jpg, .jpeg" dense filled
                                v-model="file">

                                <template v-slot:prepend>
                                    <q-icon name="attach_file" />
                                </template>
                            </q-file>
                        </div>
                        <div class="col-12">
                            <q-file label="Contrato" @input="upload3" accept=".pdf, .png, .jpg, .jpeg" dense filled
                                v-model="file2">

                                <template v-slot:prepend>
                                    <q-icon name="attach_file" />
                                </template>
                            </q-file>
                        </div>
                        <div class="col-12">
                            <q-file label="Identificación Oficial" @input="upload4" accept=".pdf, .png, .jpg, .jpeg"
                                dense filled v-model="file3">

                                <template v-slot:prepend>
                                    <q-icon name="attach_file" />
                                </template>
                            </q-file>
                        </div>
                        <div class="col-12">
                            <q-input v-model="item.habilidades" dense filled autogrow label="Habiliades" />
                        </div>
                        <div class="col-12">
                            <q-input v-model="item.objetivos" dense filled autogrow label="Objetivos" />
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12">
                    <q-list class="rounded-borders">
                        <q-expansion-item header-class="bg-grey-3" switch-toggle-side expand-separator
                            icon="perm_identity" label="Funciones">
                            <div class="row q-col-gutter-sm q-mt-sm">
                                <div class="col-12 col-md-6">
                                    <div class="col-12 col-md-12">
                                        <q-input v-model="funcion.descripcion" dense filled label="Descripción" />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <q-field dense filled label="Porcentaje" v-model="funcion.porcentaje">

                                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                            <Money :id="id" class="q-field__input text-right" :value="value"
                                                @input="emitValue" v-bind="moneyFormat" v-show="floatingLabel" />
                                        </template>

                                        <template v-slot:after>
                                            <q-btn round color="primary" icon="mdi-plus" @click="agergarFuncion()">
                                                <q-tooltip>
                                                    Buscar
                                                </q-tooltip>
                                            </q-btn>
                                        </template>
                                    </q-field>
                                </div>
                                <div class="col-12">
                                    <q-table class="shadow-0" title="" :data="item.funciones" :columns="columns"
                                        row-key="descripcion">

                                        <template v-slot:body="props">
                                            <q-tr :props="props">
                                                <q-td auto-width key="acciones">
                                                    <q-btn size="md" color="red" rounded flat dense
                                                        @click="eliminar(props.row)" icon="mdi-delete">
                                                        <q-tooltip transition-show="flip-right"
                                                            transition-hide="flip-left"
                                                            content-style="font-size: 14px">Eliminar</q-tooltip>
                                                    </q-btn>
                                                </q-td>
                                                <q-td key="descripcion" :props="props">{{ props.row.descripcion
                                                    }}</q-td>
                                                <q-td key="porcentaje" :props="props">{{ props.row.porcentaje }}</q-td>
                                            </q-tr>
                                        </template>
                                    </q-table>
                                </div>
                            </div>
                        </q-expansion-item>
                    </q-list>
                </div> -->
            </div>
        </q-card-section>
    </q-card>
</template>

<script>
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import { Money } from 'v-money'
import { WebCam } from "vue-web-cam";
import { find, head } from "lodash";

export default {
    components: {
        Money, WebCam
    },
    data() {
        return {
            funcion: {
                descripcion: '',
                porcentaje: 0,
            },
            moneyFormat: {
                decimal: '.',
                thousands: ',',
                precision: 2,
                masked: false,
                suffix: ' %',
            },
            file: null,
            file2: null,
            file3: null,
            carga: null,
            // camara
            img: null,
            camera: null,
            deviceId: null,
            dialogWebCam: false,
            devices: [],

            dialogCargarImagen: false,

            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', },
                { name: 'descripcion', align: 'center', label: 'Descripción', field: 'descripcion', },
                { name: 'porcentaje', align: 'center', label: 'Porcentaje', field: 'porcentaje' },
            ]
        }
    },
    computed: {
        item() {
            return this.$store.state.trabajadorStore;
        },
        token() {
            return this.$store.state.usuario;
        },
        fecha() {
            moment.locale('es-mx');
            return this.item.fechaNacimiento ? moment.utc(this.item.fechaNacimiento).format('DD/MMMM/yyyy') : ''
        },
        device() {
            return find(this.devices, n => n.deviceId == this.deviceId);
        }
    },
    watch: {
        camera: function (id) {
            this.deviceId = id;
        },
        devices: function () {
            // Once we have a list select the first one
            let first = head(this.devices);
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        }
    },
    created() {
    },
    methods: {
        cierraVentana() {
            this.$emit('cierraVentana')
        },
        async guardar() {
            this.$store.state.trabajadorStore.fechaCreacion = moment().format('YYYY/MM/DD');
            this.$store.state.trabajadorStore.usuarioCreador = this.token.username
            console.log(this.item)

            // if (this.item.archivo.base64 == '') {
            //     this.$q.notify({ type: 'warning', position: 'top-right', message: 'Elija el archivo PDF' })
            //     return;
            // }
            if (this.item.nombre == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Ingrese el nombre del trabajador' })
                return;
            }
            if (this.item.apellidos == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Ingrese los apellidos del trabajador' })
                return;
            }
            if (this.item.genero == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Ingrese el genero' })
                return;
            }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando trabajador. Espere...', messageColor: 'white' })

            try {
                let response = await axios.post('Trabajadores/PostTrabajador/', this.item)
                console.log(response)

                this.item._id = response.data
                this.$store.state.listaTrabajadoresStore.push(this.item)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El trabajador ha sido guardada exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async actualizar() {
            this.$store.state.trabajadorStore.fechaCreacion = moment().format('YYYY/MM/DD');
            this.$store.state.trabajadorStore.usuarioCreador = this.token.username
            console.log(this.item)

            // if (this.item.archivo.base64 == '') {
            //     this.$q.notify({ type: 'warning', position: 'top-right', message: 'Elija el archivo PDF a registrar' })
            //     return;
            // }

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Actualizando trabajador. Espere...', messageColor: 'white' })

            try {
                let response = await axios.put('Trabajadores/PutTrabajador/', this.item)
                console.log(response)

                let indice = this.$store.state.listaTrabajadoresStore.findIndex(x => x._id === this.item._id);
                Object.assign(this.$store.state.listaTrabajadoresStore[indice], this.item)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El trabajador ha sido actualizado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al actualizar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },

        async upload2(event) {
            console.log(event)
            const file = event;
            try {
                const result = await this.convertBase64(file);

                this.$store.state.trabajadorStore.cv.base64 = result;
                this.$store.state.trabajadorStore.cv.nombreArchivo = file.name;
                this.$store.state.trabajadorStore.cv.tipoArchivo = file.type;
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },

        async upload3(event) {
            console.log(event)
            const file = event;
            try {
                const result = await this.convertBase64(file);

                this.$store.state.trabajadorStore.contrato.base64 = result;
                this.$store.state.trabajadorStore.contrato.nombreArchivo = file.name;
                this.$store.state.trabajadorStore.contrato.tipoArchivo = file.type;
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },
        async upload4(event) {
            console.log(event)
            const file = event;
            try {
                const result = await this.convertBase64(file);

                this.$store.state.trabajadorStore.identificacion.base64 = result;
                this.$store.state.trabajadorStore.identificacion.nombreArchivo = file.name;
                this.$store.state.trabajadorStore.identificacion.tipoArchivo = file.type;
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },
        async uploadImagen(event) {
            console.log(event)
            const file = event;
            try {
                const result = await this.convertBase64(file);

                this.$store.state.trabajadorStore.foto = result;
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },
        convertBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
            });
        },
        onCapture() {
            this.img = this.$refs.webcam.capture();
            console.log(this.img)
            this.$store.state.trabajadorStore.foto = this.img;
            this.dialogWebCam = false
        },
        onStarted(stream) {
            console.log("On Started Event", stream);
        },
        onStopped(stream) {
            console.log("On Stopped Event", stream);
        },
        onStop() {
            this.$refs.webcam.stop();
        },
        onStart() {
            this.$refs.webcam.start();
        },
        onError(error) {
            console.log("On Error Event", error);
        },
        onCameras(cameras) {
            this.devices = cameras;
            console.log("On Cameras Event", cameras);
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
            console.log("On Camera Change Event", deviceId);
        },
        borrarImagen() {
            this.$store.state.trabajadorStore.foto = ''
            this.carga = null
        },
        agergarFuncion() {
            this.funcion.porcentaje = this.funcion.porcentaje + ' %'
            this.$store.state.trabajadorStore.funciones.push(this.funcion);
            this.funcion = {
                descripcion: '',
                porcentaje: 0,
            }
        },
        eliminar(data) {
            let editedIndex = this.item.funciones.indexOf(data)
            this.item.funciones.splice(editedIndex, 1)
        }
    }
}
</script>