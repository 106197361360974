import jsPDF from 'jspdf';
import moment from 'moment'

function FormatoFechaSinHora(value) {
    const fecha = new Date(value);
    const fechaFormateada = format(fecha, 'dd-MMMM-yyyy', { locale: es });
    return fechaFormateada.toUpperCase();
}

const Tarjeta = async (objeto) => {
    return new Promise((resolve, reject) => {
        try {
            const pdf = new jsPDF();
            // Agregar fondo y borde a la tarjeta
            // pdf.setFillColor("#007BFF");
            // pdf.rect(10, 10, 80, 90, "F");

            const rutaImagen = require('../../assets/fondo_tarjeta.jpeg');
            const watermarkImage = 'BASE64_ENCODED_IMAGE_OR_IMAGE_URL';
            const opacity = 0.5;
            const angle = 0;

            pdf.saveGraphicsState();
            pdf.addImage(rutaImagen, 'PNG', 10, 10, 70, 120, '', 'FAST', angle);
            pdf.restoreGraphicsState();

            const cardWidth = 70;
            const radius = 20;
            const imageWidth = radius * 2;
            const imageHeight = radius * 2;

            const x = (cardWidth - imageWidth) / 2 + 10;
            const y = 15;

            const base64Image = objeto.foto;

            pdf.clip(x, y, imageWidth, imageHeight);
            pdf.addImage(base64Image, 'JPEG', x, y, imageWidth, imageHeight);

            // Configurar colores y fuentes
            pdf.setTextColor("#333333");
            pdf.setFont("helvetica");

            // Agregar texto a la tarjeta con estilo
            pdf.setFontSize(13);
            pdf.setTextColor("#fff");
            pdf.setFont('helvetica', 'bold');

            let nombre = objeto.nombre + " " + objeto.apellidos
            const textHeight = 10;
            const lines = pdf.splitTextToSize(nombre, 60 - 10);
            const totalTextHeight = lines.length * textHeight;
            const yy = (110 - totalTextHeight) / 2 + 10;

            pdf.text(lines, 12, 113);
            pdf.setFontSize(12);
            pdf.text(objeto.puesto, 12, 82);
            pdf.setFontSize(12);
            pdf.text(objeto.formacion, 12, 98);

            const base64 = pdf.output('datauristring');
            resolve(base64);


        } catch (error) {
            console.log(error)
        }
    })
}

export { Tarjeta };